.Header {
  display: block;
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0px;
  z-index: 100;
  /* left: 0px; */
}

.nav-container {
  display: flex;
  width: 80%;
  height: 100%;
  margin: auto;
}

.nav-container-mobile {
  width: 100%;
  height: 100%;
}

nav {
  margin-left: auto;
  margin-top: 2em;
  margin-right: 5em;
}

.navigation-menu {
  display: flex;
  list-style: none;
}

li {
  font-family: "GlossAndBloom";
  font-size: 1.5rem;
  padding: 0 2rem;
}

/* hamburger animation */
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ham-active {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: white;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham .top {
  stroke-dasharray: 40 139;
}
.ham .bottom {
  stroke-dasharray: 40 180;
}
.ham-active .top {
  stroke-dasharray: 40 139;
  stroke-dashoffset: -98px;
}
.ham-active .bottom {
  stroke-dasharray: 40 180;
  stroke-dashoffset: -138px;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.mobile-menu-active {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  top: 80px;
  opacity: 1;
  position: absolute;
  z-index: 1;
  background-color: #282f46;
  opacity: 1;
  transition: all 0.5s ease;
  left: 0;
}

@media (max-width: 767px) {
  li {
    list-style: none;
    margin: 30px;
  }
}
